<template>
  <article class="text-content">
    <div class="container" v-html="markdownToHTML(content || '')">
    </div>
  </article>
</template>

<script>
const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true,
});

export default {
  name: 'TextContent',
  props: {
    content: {
      type: String,
    },
  },
  methods: {
    markdownToHTML(content) {
      return md.render(content);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-content {
  letter-spacing: -0.02em;
  counter-reset: sections;
  line-height: 1.6;
  font-weight: 400;

  @include wRule(font-size, (xl: 20, lg: 18, md: 16, sm: 30));

  ::v-deep(h1) {
    font-weight: 700;
    line-height: 1.2;

    @include wRule(max-width, (xl: 990, lg: 990, md: 100%));
    @include wRule(font-size, (xl: 65, lg: 50, md: 45, sm: 65));
    @include wRule(margin-bottom, (xl: 118, lg: 95, md: 80, sm: 90));
  }

  ::v-deep(h2) {
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;

    @include wRule(font-size, (xl: 30, lg: 25, md: 20, sm: 35));

    &:not(:last-child) {
      @include wRule(margin-bottom, (xl: 50, lg: 50, md: 44, sm: 60));
    }

    &:not(:first-child) {
      @include wRule(margin-top, (xl: 78, lg: 78, md: 75, sm: 50));
    }

    &::before {
      counter-increment: sections;
      content: counter(sections)'. ';
    }
  }

  ::v-deep(h3) {
    font-weight: 700;

    @include wRule(font-size, (xl: 25, lg: 20, md: 18, sm: 30));

    &:not(:first-child) {
      @include wRule(margin-top, (xl: 32, lg: 35, md: 35, sm: 30));
    }
  }

  ::v-deep(h4) {
    font-weight: 700;
  }

  ::v-deep(strong) {
    font-weight: 700;
  }

  ::v-deep(a) {
    text-decoration: underline;
    transition: color $ease-main;

    &:hover {
      color: color(border-blue);
    }
  }

  ::v-deep(ol) {
    counter-reset: list1;

    @include wRule(margin, (xl: 30 0 32, lg: 28 0 32, md: 28 0 30, sm: 24 0 30));

    li {
      &::before {
        counter-increment: list1;
        content: counter(sections)'.'counter(list1)'. ';
      }

      ol {
        counter-reset: list2;

        @include wRule(
          padding-left,
          (
            xl: 40,
            lg: 40,
            md: 40,
            sm: 50,
          )
        );

        li {
          &::before {
            counter-increment: list2;
            content: counter(sections)'.'counter(list1)'.'counter(list2)'. ';
          }
        }
      }
    }
  }

  ::v-deep(ul) {
    @include wRule(margin, (xl: 30 0 32, lg: 28 0 32, md: 28 0 30, sm: 24 0 30));

    li {
      position: relative;

      @include wRule(
        padding-left,
        (
          xl: 40,
          lg: 40,
          md: 40,
          sm: 50,
        )
      );

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        background: currentColor;
        margin-top: 0.7em;

        @include wRule(
          width,
          (
            xl: 20,
            lg: 20,
            md: 20,
            sm: 20,
          )
        );

        @include wRule(
          height,
          (
            xl: 3,
            lg: 3,
            md: 3,
            sm: 3,
          )
        );
      }

      ul {
        @include wRule(
          padding-left,
          (
            xl: 80,
            lg: 47,
            md: 25,
            sm: 0,
          )
        );

        li {
          @include wRule(
            padding-left,
            (
              sm: 45,
            )
          );
        }

        ul {
          @include wRule(
            padding-left,
            (
              xl: 80,
              lg: 50,
              md: 70,
              sm: 48,
            )
          );

          li {
            padding-left: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

</style>
